import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import { darken, lighten, styled } from '@mui/material/styles';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const columns = [
    { field: 'client_id', headerName: 'Client ID', width: 90 },

    {
        field: 'full_name',
        headerName: 'Full name',
        width: 150,
    }, {
        field: 'ref_number',
        headerName: 'Reference Number',
        width: 150,
    },
    {
        field: 'nickname',
        headerName: 'Nick name',
        width: 150,
    },
    {
        field: 'email',
        headerName: 'email',
        width: 150,
    },
    {
        field: 'usr_name',
        headerName: 'Tailor User Name',
        width: 180,
    },

    {
        field: 'order_id',
        headerName: 'Order ID',
        width: 100,
    },
    {
        field: 'remark',
        headerName: 'Remark',
        width: 150,
    },
    {
        field: 'state_str',
        headerName: 'State',
        width: 100,
    }, {
        field: 'quantity',
        headerName: 'Num of shirts',
        width: 100,
    }, {
        field: 'items',
        headerName: 'Order Items',
        width: 100,
    }, {
        field: 'order_time',
        headerName: 'Order time',
        width: 180,
    },


];

const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--10': {
        backgroundColor: getBackgroundColor(theme.palette.warning.main, theme.palette.mode),

        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--20': {
        backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),

        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--30': {
        backgroundColor: getBackgroundColor(
            theme.palette.secondary.main,
            theme.palette.mode,
        ),
        color: "red",
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--40': {
        // backgroundColor: getBackgroundColor(
        //     theme.palette.warning.main,
        //     theme.palette.mode,
        // ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--50': {
        backgroundColor: getBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
        },
    },
}));

const columnGroupingModel = [
    {
        groupId: 'Internal',
        description: '',
        children: [{ field: 'id' }],
    },
    {
        groupId: 'Basic info',
        children: [
            {
                groupId: 'Full name',
                children: [{ field: 'last_name' }, { field: 'first_name' }],
            },
            { field: 'age' },
        ],
    },
];

export default function OrdersMan(props) {

    const [filterNewOrder, setFilterNewOrder] = useState(true);
    const [filterAccepted, setFilterAccepted] = useState(false);
    const [filterApproved, setFilterApproved] = useState(false);
    const [filterProcess, setFilterProcess] = useState(false);
    const [filterSent, setFilterSent] = useState(false);
    const [filterRequest2Cancel, setRequest2Cancel] = useState(true);
    const [filterReceived, setReceived] = useState(true);

    const [filterSelect, setFilterSelect] = useState("0");
    const [searchText, setSearchText] = useState("");

    const [gridData, setGridData] = useState([]);

    let passdate = new Date();
    passdate.setDate(passdate.getDate() - 10);
    let newdate = new Date();
    newdate.setDate(newdate.getDate() + 1);

    const navigate = useNavigate();

    const [fromDate, setFromDate] = useState(dayjs(passdate));
    const [toDate, setToDate] = useState(dayjs(newdate));

    let tailorshop_id = "0";
    const auth = useAuth();
    if (auth.user == null) {
        auth.logOut();
        // return;
    } else {
        tailorshop_id = auth.user.tailorshop_id;
    }

    const addId = (collection, idName) => {
        return collection.map(item => {
            // console.log('item:', {
            //     ...item,
            //     id: item[idName],
            // });
            return {
                ...item,
                id: item[idName],
            };
        });
    };

    const processData = async (data) => {
        return await data.map(item => {
            let client = JSON.parse(item.data_json);
            let state_str = "";

            switch (parseInt(item['state'] + "")) {
                case 10:
                    state_str = "New order";
                    break;
                case 20:
                    state_str = "Accepted";
                    break;
                case 30:
                    state_str = "Approved";
                    break;
                case 31:
                    state_str = "Request to cancel";
                    break;
                //----------------------------
                case 40:
                    state_str = "Processed";//"Put in PB";
                    break;
                case 60:
                    state_str = "Sent"; //"Put in SB";
                    break;
                case 90:
                    state_str = "Received";
                    break;
                default:
                    state_str = item['state']
            }

            let data = JSON.parse(item['data_json']);

            // let orderItems = [];
            // fetch("/api/orderitems/" + item['order_id'], {
            //     method: "GET",
            //     headers: getHeaders(auth),
            // })
            //     .then((response) => response.json())
            //     //.then((jsonData) => addId(jsonData, "client_id"))
            //     .then((rowData) => {
            //         if (rowData["result"]) { auth.logOut(); return;}

            //         orderItems = rowData;

            //     })
            //     .catch((error) => console.log(error));

            return {
                ...item,
                id: item['order_id'],
                full_name: (client['full_name'] ? client['full_name'] : ""),
                nickname: (client['nickname'] ? client['nickname'] : ""),
                email: (client['email'] ? client['email'] : ""),
                ref_number: (client['ref_number'] ? client['ref_number'] : ""),
                state_str: state_str,
                data: data,
                orderItems: []
            };
        });
    }

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleRowClick = (params) => {
        console.log('Click Row: ', params.row);
        navigate("/orderdetail?id=" + params.row['order_id']);
        //  props.selectClient(params.row, false);
    };

    const handleFilterSelect = (event) => {
        setFilterSelect(event.target.value);
    }

    const handleSearchBtn = () => {
        // if (searchText == null || searchText == undefined || searchText === "") {
        //     return;
        // }

        fetch("/api/searchorders/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({

                filterNewOrder,
                filterAccepted,
                filterApproved,
                filterProcess,
                filterSent,
                filterRequest2Cancel,
                filterReceived,

                fromDate,
                toDate,
                filterSelect,
                searchText: searchText,
                //tailorshop_id
            }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                processData(rowData).then((rowData2) => {
                    // console.log(rowData2);
                    rowData2.map((item) => {
                        let orderItems = [];
                        fetch("/api/orderitems/" + tailorshop_id + "/" + item['order_id'], {
                            method: "GET",
                            headers: getHeaders(auth),

                        })
                            .then((response) => response.json())
                            //.then((jsonData) => addId(jsonData, "client_id"))
                            .then((rows) => {
                                if (rows["result"]) { auth.logOut(); }


                                //  let order = new Set(rows);
                                rows.map((o) => {
                                    let data = JSON.parse(o['data_json']);
                                    delete data['shirtPattern'];
                                    o['data'] = data;

                                    delete o['data_json'];
                                });
                                item['orderItems'] = rows;
                                item['tailorshop'] = { tailorshop_id, short_name: auth.user.short_name };
                                //  console.log("DEBUG !!", rows);

                            })
                            .catch((error) => console.log(error));
                    });
                    setTimeout(() => { setGridData(rowData2); }, 500);
                });


            })
            .catch((error) => console.log(error));
        //fetchData();

    }


    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        // fetch("/api/allorders", {
        //     method: "GET",
        //     headers: getHeaders(auth),
        //     //body: JSON.stringify(data),
        // })
        //     .then((response) => response.json())
        //     //.then((jsonData) => addId(jsonData, "client_id"))
        //     .then((rowData) => {
        //         if (rowData["result"]) { auth.logOut(); return; }
        //         processData(rowData).then((rowData2) => {
        //             // console.log(rowData2);
        //             setGridData(rowData2);
        //         });

        //     })
        //     .catch((error) => console.log(error));
        //fetchData();
    }, []);


    const printToPdf = () => {

    }

    const handleProcessBtn = (event) => {

        alert("Print to PDF");
    }

    const handleFilterNewOrder = (event) => {
        if (!filterNewOrder || filterAccepted || filterApproved || filterProcess || filterSent || filterRequest2Cancel || filterReceived) {
            setFilterNewOrder(event.target.checked);
        }
    }

    const handleFilterAccepted = (event) => {
        if (filterNewOrder || !filterAccepted || filterApproved || filterProcess || filterSent || filterRequest2Cancel || filterReceived) {
            setFilterAccepted(event.target.checked);
        }
    }
    const handleFilterApproved = (event) => {
        if (filterNewOrder || filterAccepted || !filterApproved || filterProcess || filterSent || filterRequest2Cancel || filterReceived) {
            setFilterApproved(event.target.checked);
        }
    }

    const handleFilterProcess = (event) => {
        if (filterNewOrder || filterAccepted || filterApproved || !filterProcess || filterSent || filterRequest2Cancel || filterReceived) {
            setFilterProcess(event.target.checked);
        }

    }

    const handleFilterSent = (event) => {
        if (filterNewOrder || filterAccepted || filterApproved || filterProcess || !filterSent || filterRequest2Cancel || filterReceived) {
            setFilterSent(event.target.checked);
        }
    }

    const handleFilterRequest2Cancel = (event) => {
        if (filterNewOrder || filterAccepted || filterApproved || filterProcess || filterSent || !filterRequest2Cancel || filterReceived) {
            setRequest2Cancel(event.target.checked);
        }
    }

    const handleReceived = (event) => {
        if (filterNewOrder || filterAccepted || filterApproved || filterProcess || filterSent || filterRequest2Cancel || !filterReceived) {
            setReceived(event.target.checked);
        }
    }



    // const handleTailorShopSelect = (event) => {
    //     setTailorShop(event.target.value);

    // }

    useEffect(() => { handleSearchBtn(); }, [filterNewOrder, filterAccepted, filterApproved, filterProcess, filterSent, filterRequest2Cancel, filterReceived]);


    useEffect(() => {

        if (auth.user == null) {
            auth.logOut();
            return;
        }


        handleSearchBtn();

    }, []);

    return (<>


        <div className="container p-0">

            <div className="m-1 row p-0">
                <div className="col">
                    <div className="container p-0">
                        <div className="row-sm m-1 " style={{ flexFlow: "row", display: "flex" }}>

                            <select className="form-select m-2 " aria-label="Select field to search and filter"
                                style={{ height: "55px", width: "450px" }}
                                onChange={handleFilterSelect} >
                                <option value="0" defaultValue>No filter</option>
                                <option value="1">1. Order ID</option>
                                <option value="2">2. Client ID</option>
                                <option value="3">3. Full name</option>
                                <option value="4">4. Reference number</option>
                                <option value="5">5. Fabric</option>
                                <option value="6">6. Email</option>
                                <option value="7">7. Phone number</option>
                                <option value="8">8. Prod Batch</option>
                                <option value="9">9. Send Batch</option>
                            </select>

                            <input type="text" className="form-control m-2" id="searchText"
                                value={searchText} placeholder="Enter text"
                                onChange={changeSearchText}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter")
                                        handleSearchBtn();
                                }} />
                            <span><button type="button" className="btn btn-success m-2" onClick={handleSearchBtn}><SearchIcon /></button></span>

                        </div>
                        <div className="row-sm m-1" style={{ flexFlow: "row", display: "flex" }}>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state1" checked={filterNewOrder} onChange={handleFilterNewOrder} />
                                <label className="form-check-label p-1" htmlFor="state1"> New</label>
                            </div>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state2" checked={filterAccepted} onChange={handleFilterAccepted} />
                                <label className="form-check-label p-1" htmlFor="state2"> Accepted</label>
                            </div>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state3" checked={filterApproved} onChange={handleFilterApproved} />
                                <label className="form-check-label p-1" htmlFor="state3"> Approved</label>
                            </div>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state4" checked={filterProcess} onChange={handleFilterProcess} />
                                <label className="form-check-label p-1" htmlFor="state4"> Processed</label>
                            </div>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state5" checked={filterSent} onChange={handleFilterSent} />
                                <label className="form-check-label p-1" htmlFor="state5"> Sent</label>
                            </div>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state6" checked={filterRequest2Cancel} onChange={handleFilterRequest2Cancel} />
                                <label className="form-check-label p-1" htmlFor="state6"> Request to cancel</label>
                            </div>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state7" checked={filterReceived} onChange={handleReceived} />
                                <label className="form-check-label p-1" htmlFor="state7"> Received</label>
                            </div>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <DatePicker label="From Date"
                                    value={fromDate}
                                    onChange={(newValue) => setFromDate(newValue)} />&nbsp;
                                <DatePicker label="To Date"
                                    value={toDate}
                                    onChange={(newValue) => setToDate(newValue)} />

                            </LocalizationProvider>
                        </div>


                    </div>
                </div>


            </div>

            <StyledDataGrid
                rows={gridData}
                columns={columns}
                checkboxSelection={false}
                disableMultipleRowSelection={true}
                columnGroupingModel={columnGroupingModel}
                density='compact'
                onRowClick={handleRowClick}
                //onRowSelectionModelChange={handleSelectionChange}

                pageSizeOptions={[5, 10, 25, 50, 100]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                    },
                }}
                getRowClassName={(params) => `super-app-theme--${params.row.state}`}
            />
            Total: {gridData.length} row(s)
        </div>
    </>
    );
}

