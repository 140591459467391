import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import SearchIcon from '@mui/icons-material/Search';
import Rating from '@mui/material/Rating';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import Collapse from '@mui/material/Collapse';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useSpring, animated } from '@react-spring/web';


import './FabricSelect.css';
import { styled } from '@mui/material';


const ITEMS = [
    {
        id: '1',
        label: 'Fabric Sample Set #1',
        children: [
            {
                id: '2', label: 'Business Cotton',

            },
            { id: '4', label: 'Patterns' },
            { id: '5', label: 'Cotton' },
            { id: '6', label: 'Casual Cotton' },
        ],
    },
];

function TransitionComponent(props) {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

export default function FabricSelect(props) {

    const [treeItems, setTreeItems] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [activeFabric, setActiveFabric] = useState("");
    const [fabricsData, setFabricsData] = useState(props.fabricsData);
    const [viewFabric, setViewFabric] = useState(props.fabricsData);

    const auth = useAuth();
    if (auth.user == null) {
        auth.logOut();
        // return;
    }

    const curData = props.orderData;

    const updateSearch = (str) => {
        if (str == null) {
            setViewFabric(fabricsData);
            return;
        }
        str = str.toUpperCase();
        let temp = [];
        fabricsData.forEach((e) => {
            if (e['fabric_code'].indexOf(str) >= 0) {
                temp.push(e);
            }
        });
        setViewFabric(temp);
    }

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
        if (event.target.value === "") {
            setSearchText(null);

        }

        updateSearch(event.target.value);
    }

    const handleSearchBtn = (event) => {
        if (searchText == null || searchText === "") {
            setSearchText(null);
        }
        updateSearch(searchText);
    }

    const handleCategoryClick = (event, itemIds) => {
        event.stopPropagation();
        // console.log(itemIds);
        if (itemIds === "1") {
            setViewFabric(fabricsData);
        } else {
            let temp = [];
            fabricsData.forEach((e) => {
                if (e['category_id'] == itemIds) {
                    temp.push(e);

                }

            });
            //setTimeout(() => { 
            setViewFabric(temp);
            //}, 500)
            // console.debug('ViewFabric:', temp);
        }
    }

    const handleImgClick = (event) => {

        setActiveFabric(event.target.id);
        let temp = { ...curData };
        let fabric = viewFabric.find(obj => obj['fabric_code'] === event.target.id);
        console.debug("DEBUG: fabric=", fabric)

        if (temp['cur_order'] === null || temp['cur_order'] === undefined) {
            temp['cur_order'] = 0;
            temp['order_infos'] = [{ fabric_code: fabric.fabric_code, small_img: fabric.small_img, num_of_shirt: 1, notes: "" }];
        } else {
            temp.order_infos[temp.cur_order] = { fabric_code: fabric.fabric_code, small_img: fabric.small_img, num_of_shirt: 1, notes: "" };
        }


        props.selectFabric(temp, true);
        //   console.log(event.target);
    }

    const getChildren = (rowData, parent_id) => {
        let children = [];
        if (rowData.length !== 0) {
            rowData.forEach(e => {
                if (e['category_parent'] == parent_id) {
                    let item = {
                        id: e['category_id'],
                        label: e['name'],
                        children: getChildren(rowData, e['category_id'])
                    }
                    if (item.children.length == 0) {
                        delete item.children;
                    }
                    children.push(item);
                }
            });
        }
        return (children);
    }

    useEffect(() => {
        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/catbyid/FabricCat/all", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                //   console.log(rowData);
                var treeitems = [];
                let parent_id = null;
                let curParent = null;
                if (rowData.length !== 0) {
                    rowData.forEach(e => {
                        if (e['category_parent'] === "0") {
                            let item = {
                                id: e['category_id'],
                                label: e['name'],
                                children: getChildren(rowData, e['category_id'])
                            }
                            treeitems.push(item);
                        }
                    });
                }

                setTimeout(() => {
                    // console.debug("TREEITEMS:", treeItems);
                    setTreeItems(treeitems);
                }, 300);

            })
            .catch((error) => console.log(error));



        if (props.fabricsData.length > 0) return;
        fetch("/api/fabrics/" + auth.user['tailorshop_id'], {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                // const rowData2 = addId(rowData, "client_id");
                setFabricsData(rowData);
                setViewFabric(rowData);
                props.setFabricsData(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    return (
        <>
            <div className='container-md'>
                <div className='row'>
                    <div className='col-3'>
                        <strong>Fabric Category</strong>
                        {treeItems.length > 0 &&
                            <RichTreeView
                                aria-label="customized"
                                defaultExpandedItems={['0']}
                                sx={{ overflowX: 'hidden', minHeight: 270, flexGrow: 1, maxWidth: 300 }}
                                slotProps={{ item: { slots: { groupTransition: TransitionComponent } } }}
                                onSelectedItemsChange={handleCategoryClick}
                                items={treeItems}
                            />
                        }

                    </div>
                    <div className='col-9'>
                        <div className="mb-3 row p-0">
                            <label htmlFor="searchText" className="col-sm-2 col-form-label">Search fabric:</label>
                            <div className="col-sm-10 " style={{ flexFlow: "row", display: "flex" }}>
                                <input type="text" className="form-control" id="searchText" value={searchText} placeholder="Enter fabric code" onChange={changeSearchText}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter")
                                            handleSearchBtn();
                                    }} />
                                <span><button type="button" className="btn btn-success" onClick={handleSearchBtn}><SearchIcon /></button></span>
                            </div>

                        </div>
                        <div className="d-flex justify-content-center p-12">
                            <p />
                            {/* srcSet={`${item.small_img}?w=300&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.small_img}?w=300&fit=crop&auto=format`} */}

                            <ImageList sx={{ width: "1024px", height: "800px" }} cols={6}>
                                {viewFabric.map((item) => (
                                    <ImageListItem key={item.fabric_code}>
                                        <div className='img-container'  >
                                            <img
                                                srcSet={`${item.small_img}?auto=format&dpr=2 2x`}
                                                src={`${item.small_img}?auto=format`}
                                                alt={item.full_name}
                                                id={item.fabric_code}
                                                onClick={handleImgClick}
                                                style={{ display: "blocks", borderRadius: "10%", boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)" }}
                                                className='holder'
                                            />
                                            <div id={item.fabric_code} className="overlay" onClick={handleImgClick}>Click to select</div>
                                        </div>
                                        {(activeFabric == item.fabric_code) ? <ImageListItemBar
                                            title={item.full_name}
                                            // subtitle={<span>code: {item.code}</span>}
                                            position="below"
                                            sx={{
                                                "& .MuiImageListItemBar-title": { color: "white", border: "1px solid blue", backgroundColor: "#1976d2" }, //styles for title
                                            }} />
                                            : <ImageListItemBar
                                                title={item.full_name}
                                                // subtitle={<span>code: {item.code}</span>}
                                                position="below"
                                                sx={{
                                                    "& .MuiImageListItemBar-title": { color: "black" }, //styles for title
                                                }} />
                                        }

                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}